import {useState, useEffect, useContext, createContext, useCallback} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useChoicesContext } from '../context/ChoicesContext';

export const TokenContext = createContext(null);

export const useAuthLogic = () => {
    const { isAuthenticated, getIdTokenClaims, isLoading } = useAuth0();
    const {
        setReferringClientData,
        setEmail,
        setState,
        setFirstName,
        setLastName,
        setClientDomainData,
        setUserId,
        setSessionId
    } = useChoicesContext();

    const [token, setToken] = useState(null);
    const [hasInitialized, setHasInitialized] = useState(false);

    const executeAuthLogicSetup = useCallback(async() => {
        try {
            console.log("AuthLogic setup started...");

            const idClaims = await getIdTokenClaims();

            const email = idClaims.email;
            const firstName = idClaims.given_name;
            const lastName = idClaims.family_name;
            const userId = idClaims.sub;

            const state = idClaims['state'];
            const domainId = idClaims['domain_id'];
            const domainUrl = idClaims['domain_url'];
            const clientId = idClaims['https://whlchoices.com/client_id'];
            const expireTime = idClaims.exp * 1000;
            const currentTime = Date.now();
            const timeout = expireTime - currentTime - 60000;

            console.log("Storing values to localStorage and context");
            localStorage.setItem('choices-clientId', clientId);
            localStorage.setItem('choices-email', email);
            localStorage.setItem('choices-firstName', firstName);
            localStorage.setItem('choices-lastName', lastName);

            setEmail(email);
            setFirstName(firstName);
            setLastName(lastName);
            setState(state);
            setClientDomainData(clientId, domainId);
            setUserId(userId);
            setSessionId(idClaims.sid);
            setReferringClientData(clientId, domainId, domainUrl);

            setToken(idClaims);
            setHasInitialized(true);

            console.log("AuthLogic setup complete.");
            setTimeout(executeAuthLogicSetup, timeout);

        } catch (error) {
            console.error("AuthLogic error during auth setup", error);
        }
    }, [getIdTokenClaims]);

    useEffect(() => {
        if(isLoading){
            console.log("Waiting for Auth0 to load...");
            return;
        }
        if(!isAuthenticated) {
            console.warn("Not Authenticated.");
            return;
        }

        const run = async() => {
            console.log("Authenticated. Running setup.");
            await executeAuthLogicSetup();
        };

        run();
    }, [isLoading, isAuthenticated, executeAuthLogicSetup]);

    return { token, isAuthenticated,isLoading, hasInitialized };
};
