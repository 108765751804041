import React, {useEffect, useState} from 'react';
import AnalyticsService from '../../services/analytics.service';
import { Spinner } from 'react-bootstrap';
import { useAuthLogic, TokenContext } from '../../hooks/useAuthLogic';
import { useNavigate } from 'react-router-dom';

export const OAuthRedirect = ({ children }) => {
    const { token, isAuthenticated, isLoading, hasInitialized } = useAuthLogic();
    const navigate = useNavigate();
    const [timeoutTriggered, setTimeoutTriggered] = useState(false);

    useEffect(() => {
        if (isAuthenticated && hasInitialized) {
            console.log("Auth complete. Redirecting...");
            AnalyticsService.trackReturnVisitor();
            navigate('/');
        }
    }, [isAuthenticated, hasInitialized, navigate]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!hasInitialized) {
                console.warn('Auth Fallback timeout triggered after 8 seconds');
                setTimeoutTriggered(true);
            }
        }, 8000);
        return () => clearTimeout(timeout);
    }, [hasInitialized]);

    if (isLoading || isAuthenticated === undefined || (!hasInitialized && !timeoutTriggered)) {
        console.log("Loading...");
        return <Spinner animation="border" />;
    }

    if (timeoutTriggered) {
        console.error('Auth Something went wrong. Page is stuck.');
    }

    return <TokenContext.Provider value={token}>{children}</TokenContext.Provider>;
};
